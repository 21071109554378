@import "modules/responsive-type.scss";

$primary:#828282;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

@import url('https://fonts.googleapis.com/css?family=Abel|Kanit:500i');

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-family: 'Abel', sans-serif;
    font-size: 1rem;
}

h1 {
    font-family: 'Kanit', sans-serif;
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
    font-family: 'Kanit', sans-serif;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

hr.style-two {
    border: 0;
    height: 1px;
    width: 75%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}


.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: #828282;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

// @mixin btn {
//     border: none;
//     border-radius: $border-radius;
//     background: $primary;
//     color: $wht;
//     padding: 0.5em 2em;
//     font-size: 1.25em;
//     font-weight: bold;
//     text-align: center;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     display: inline-block;
//     margin: 2em 0em;
//     transition: 0.7s ease all;
//     &:hover {
//         background: lighten($primary, 10%);
//         color: lighten($wht, 10%);
//         text-decoration: none;
//         @media (max-width: 1024px) {
//             background: $secondary;
//             color: $wht;
//             box-shadow: 0px 0px 5px $blk;
//         }
//     }
// }

// .btn-default {
//     @include btn;
// }

// .modal-dialog {
//     max-width: 300px;
//     text-align: center;
//     margin: 6em auto;
//     .close {
//         display: none;
//     }
//     .modal-content {
//         color: $primary;
//         h2 {
//             text-align: center;
//         }
//     }
//     input {
//         border: 1px solid #777;
//         text-align: center;
//     }
//     input#username,
//     input#password {
//         @media (max-width: 1024px) {
//             font-size: 16px;
//         }
//     }
//     button {
//         background: transparent;
//         color: $wht;
//         display: block;
//         border: none;
//         padding: 1em 2em;
//         margin: 0 auto;
//         font-size: 18px;
//         &:hover {
//             background: transparent;
//             color: #fff;
//             box-shadow: none;
//             text-shadow: 0px 0px 3px $blk;
//             border: none;
//         }
//     }
//     input[type="submit"] {
//         @include btn;
//         display: block;
//         width: 75%;
//         font-size: 18px;
//         margin: 0 auto;
//     }
// }

// .modal-header,
// .modal-footer {
//     background: $primary;
//     color: $wht;
// }

// .modal-open {
//     overflow: auto;
//     padding-right: 0px !important;
// }

// input#username {
//     margin-bottom: 20px;
// }


/** END LOGIN FORM **/


// input#username,
// input#password {
//     width: 100%;
//     @media (max-width: 1024px) {
//         font-size: 16px;
//     }
// }

// select {
//     -moz-appearance: none;
//     text-indent: 0.01px;
//     text-overflow: '';
// }

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 5px;
    }
}

// .top-pad {
//     padding: 3em;
//     background: white;

//     @media (max-width: 990px) {
//         padding: 1em;
//     }    
// }

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

// .terms,
// .privacy {
//         font-size: 1rem;
    
//     & ol {
//         li {
//             font-size: 1rem;
//         }
//         & ol li {
//             font-size: 0.85rem;
//         }
//     }
// }



.padTop {
  padding-top: 25px;
}

.padBot {
  padding-bottom: 25px;
}

.roundthis{
    border-radius: 15px;
}

.borderthis {
  border: 3px solid #999;
}

.padthis{
    padding: 5px 5px;
}

.intro{
    background: url(../img/couple.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 50%;
    padding: 200px 0px;

    @media (max-width: 991px) {
        background-position: 14% 50%;
        padding: 180px 0px;
    }
    @media (max-width: 767px) {
        background-position: 0% 50%;
        padding: 160px 0px;
    }

    @media (max-width: 568px) {
        background-position: 11% 50%;
        padding: 150px 0px;
    }

    @media (max-width: 320px) {
        background-position: 24% 50%;
        padding: 140px 0px;
    }

    h1{
        // font-family: 'Arial', sans-serif;
        padding: 10px 0 8px 94px;
        width: 430px;
        margin-left: 181px;
        font-style: italic;
        font-size: 27px;
        color: #fff;
        text-shadow: 0 0 5px rgba(61, 61, 61, 0.2);
        text-align: left;
        line-height: 50px;
        background: rgb(130, 130, 130);
        border-radius: 15px;
        
        @media (max-width: 500px) {
            padding: 20px 19px 16px 94px;
            width: 320px;
            margin-left: 181px;
            font-style: italic;
            font-size: 16px;
            color: #fff;
            text-shadow: 0 0 5px rgba(61, 61, 61, 0.2);
            text-align: left;
            line-height: 40px;
            background: #828282;
            border-radius: 15px;
        }

        @media (max-width: 414px) {
            padding: 10px 0 8px 94px;
            width: 240px;
            margin-left: 170px;
            font-style: italic;
            font-size: 13px;
            color: #fff;
            text-shadow: 0 0 5px rgba(61, 61, 61, 0.2);
            text-align: left;
            line-height: 30px;
            background: #828282;
            border-radius: 15px;
        }

        @media (max-width: 320px) {
            padding: 10px 0 8px 94px;
            width: 240px;
            margin-left: 170px;
            font-style: italic;
            font-size: 13px;
            color: #fff;
            text-shadow: 0 0 5px rgba(61, 61, 61, 0.2);
            text-align: left;
            line-height: 30px;
            background: #828282;
            border-radius: 15px;
        }
    }


    .badge {
        background: url(../img/bub.png) no-repeat 0 0;
        width: 139px;
        height: 252px;
        position: relative;
        left: 124px;
        top: 270px;
        padding: 30px 20px 30px;

        @media (max-width: 991px){
            display: none;
        }

        p{
            font-size: 0.95rem;

            @media (max-width: 768px) {
                font-size: 1.2rem;
            }
        }
    




    .highlight {
        color: #fe9556;
        font-size: 20px;
        text-transform: uppercase;
        }
    }


    .text-container{

        width: auto;
        height: 0px;
        position: absolute;
        left: -120px;
        bottom: 0;

        .tag-container{

        @media (max-width: 991px){
            display: none;
            }
        }
    
    }


    .frame-container{

        padding: 0px 0px;
        // background:rgba(12, 12, 12, 0.26);
    
        // .crush{
        //     @media (max-width: 320px) {
        //         max-width: 270px !important;
        //     }
        // } 

    }


}

.links {
    padding: 40px 0px;
    background: #828282;

    @media (max-width: 520px) {
        padding: 40px 0px 60px;
    }

    .clearfix:after{
        content:"";
        display:block;
        clear:both;
    }

    .wrap{
        /*width: 980px;*/
        margin: 0 auto;
        // position: relative;
        padding: 0 10px;


    }

    .social {
    margin-left: 50px;
    // width: 194px;
    // height: 34px;
        @media (max-width: 520px) {
           margin-left: 20px; 
           padding-top: 15px;
        }

        .social_item {
            display: inline-block;
            width: 34px;
            height: 34px;
            margin-left: 5px;
            border-radius: 50%;
            background-color: #484848;
            background-image: url(../img/icons.png);
            transition: background-color 0.3s linear;
            position: relative;
            top: 15px;
            }
            
            &:first-child{
                // margin: 0 auto;
            }

        .fb{
            background-position:0 0;
            &:hover{
                background-color:#333;
            }
        }
        .tw{
            background-position:-40px 0;
            &:hover{
                background-color:#333;
            }
        }
        .youtube{
            background-position:-80px 0;
            &:hover{
                background-color:#333;
            }
        }
        .google{
            background-position:-120px 0;
            &:hover{
                background-color:#333;
            }
        }
        .inst{
            background-position:-160px 0;
            &:hover{
                background-color:#333;
            }
        }
    }

    ul li:first-child {
        // margin-left: 3px;
    }

    ul {
        text-align: center;
    }

    ul, ol {
        list-style: none;
    }

    ul li {
        height: 91px;
        vertical-align: top;
        line-height: 88px;
        font-size: 12px;
        display: inline-block;
        zoom: 1;

        @media (max-width: 520px) {
            display: block;
            height: 20px;
            vertical-align: top;
            line-height: 40px;
        }
    }

    ul li a {
        color: white;
        text-decoration: none;
        margin-left: 50px;

        @media (max-width: 520px) {
        margin-left: 20px;      
        }
    
        &:hover {
            text-decoration: underline;
        }
    }
}



.info{
    background: #333;
    padding: 25px 10px;

    .rowpad {
        padding-bottom: 45px;   
    }
    
    h2, p{
        color: #fff;
    }

    h2{
        padding-bottom: 5px;
        font-size: 15px;
        font-weight: bolder;
        text-transform: uppercase;
    }
    
    p{
        font-size: 15px;
    }
}

.links2{ 

    background: #333;
    margin:0 auto;
    vertical-align:top;
    padding: 25px 10px;
    
    .col-xxs-12{
        @media(max-width: 640px) {
             width: 100%;
        }

        @media(max-width: 320px) {
             width: 100%;
        }
    }

    h1{
        color:#fff;
    }

    a{
        color:#fff;

        &:hover{
            text-decoration: underline;
        }
    }

    .innerList{
        list-style-type:disc;
        color: #fff;

        li{
            margin-left: 15px;
            /*display: block;*/

            @media (max-width: 640px) {
                 margin-left: 35%;
            }

            @media (max-width: 568px) {
                 margin-left: 32%;
            }

            @media (max-width: 400px) {
                 margin-left: 25%;
            }
            
            @media (max-width: 320px) {
                 margin-left: 14%;
            }

        }      
    }
}


.testimonials {
    /*margin: 0 auto;*/
    /*max-width: 1366px;*/
    background: #484848;
    padding: 25px 0;

    .bubbles{
    
        .age{
            color:#fff;
            font-size:13px;
        }

        .name{
            color:#828282;
            font-size:25px;
        }
        
    }

    .catch{
        padding-bottom: 30px;
        color: #fff;
    }

    .bubble{
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        box-sizing:border-box;
    /*  width:309px;
        height:166px;*/
        padding:35px 17px;
        margin-bottom:20px;
        background:#fff;
        box-shadow:1px 1px 0 #774638;
        border-radius:10px;
        font-size:14px;
        position:relative;
        min-height: 225px; 
        margin-left:0;

        @media (max-width: 991px){
            min-height: auto;
        }

        &:before,&:after{
            content:'';
            display:block;
            position:absolute;
        }

        &:before{
            width:40px;
            height:20px;
            top:0;
            left:20px;
            background:url(../img/quotes.jpg) no-repeat center center #757575;
            border-radius:0 0 5px 5px;
        }

        &:after{
            bottom:-17px;
            left:0;
            border:17px solid transparent;
            border-top:17px solid #fff;
            border-left:17px solid #fff;
            height:0px;
            width:0px;
        }
    }
    .members img {
        /*width: 125px;*/
        /*max-height: 125px;*/
        /*margin: 0px 5px;*/
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 50%;
        /*border: 2px solid #666;*/
        
        @media (max-width: 767px){
             padding: 0em 0em;
        }
    }
}

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 11px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 11px;
        @media (max-width: 990px) {
            font-size: 10px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 11px;
        @media (max-width: 990px) {
            font-size: 10px;
        }
    }

    .imgadj{
        margin-top: -10px;
    }
}